<!--
 * @Author: 智客云网络科技
 * @Date: 2021-11-02 15:36:32
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-17 15:14:18
 * @Description: 库位管理
 * @FilePath: \src\views\App\Storage\Storage\StorageManage.vue
-->

<template>
  <div class="vue-box">
    <!-- ------------- 检索 ------------- -->
    <div class="table-search-box">
      <div class="table-search-title">检索</div>
      <div class="table-search-content">
        <div class="table-search-item">
          <label>组织筛选：</label>
          <organization-picker v-model="p.kor_id"></organization-picker>
        </div>

        <div class="table-search-item">
          <label>仓库筛选：</label>
          <el-select size="small" v-model="p.kwh_id">
            <el-option
              v-for="item in kwhList"
              :key="item.kwh_id"
              :label="item.name"
              :value="item.kwh_id"
            ></el-option>
          </el-select>
        </div>

        <div class="table-search-btn">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="
              p.pageNo = 1;
              _Load();
            "
          >
            查询
          </el-button>
        </div>
      </div>
    </div>

    <!-- ------------- 快捷按钮 ------------- -->
    <div class="table-btn">
      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="_OpenNewStorageModel()"
        >添加新储位</el-button
      >
      <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="_OpenBatchNewStorageModel()"
        >批量生成空储位</el-button
      >
      <el-button
        size="small"
        type="info"
        icon="el-icon-refresh"
        @click="_Load()"
        >刷新</el-button
      >
      <!-- <el-button
        size="small"
        type="info"
        icon="el-icon-refresh"
        @click="_OpenPrintStorage()"
        >测试打印</el-button
      > -->
    </div>

    <!-- ------------- 数据列表 ------------- -->
    <el-table
      class="data-table"
      :data="TableData"
      size="mini"
      stripe
      border
      row-key="id"
    >
      <!-- ID -->
      <el-table-column
        label="ID"
        prop="Aid"
        align="center"
        width="50px"
      ></el-table-column>

      <!-- 电子标签ID -->
      <el-table-column
        label="电子标签ID"
        prop="tag_id"
        align="center"
        width="130px"
      ></el-table-column>

      <!-- 存储方式 -->
      <el-table-column label="存储方式" align="center" width="115px">
        <template slot-scope="s">
          <el-tag size="small" effect="plain" v-if="s.row.type == 140010"
            >货架存放</el-tag
          >
          <el-tag size="small" effect="plain" v-else-if="s.row.type == 140011"
            >货架行存</el-tag
          >
          <el-tag size="small" effect="plain" v-else-if="s.row.type == 140012"
            >散堆存放</el-tag
          >
          <el-tag size="small" effect="plain" v-else-if="s.row.type == 140013"
            >垛堆存放</el-tag
          >
          <el-tag size="small" effect="plain" v-else-if="s.row.type == 140014"
            >成组堆码存放</el-tag
          >
          <el-tag size="small" effect="plain" type="info" v-else>未定义</el-tag>
        </template>
      </el-table-column>

      <!-- 储位路由 -->
      <el-table-column
        label="库位路由"
        prop="role"
        align="center"
        width="230px"
      ></el-table-column>

      <!-- 客户昵称 -->
      <el-table-column
        label="客户昵称"
        prop="kcu_name"
        align="center"
        width="150px"
      ></el-table-column>

      <!-- 当前状态 -->
      <el-table-column label="当前状态" align="center" width="80px">
        <template slot-scope="s">
          <el-tag
            size="small"
            effect="plain"
            type="success"
            v-if="s.row.status == 1"
            >启用</el-tag
          >
          <el-tag size="small" effect="plain" type="danger" v-else>禁用</el-tag>
        </template>
      </el-table-column>

      <!-- 描述 -->
      <el-table-column label="描述">
        <template slot-scope="s"
          ><span>{{ s.row.notes }}</span></template
        >
      </el-table-column>

      <!-- 添加时间 -->
      <el-table-column
        label="创建时间"
        prop="add_time"
        align="center"
        width="140px"
      ></el-table-column>

      <!-- 操作 -->
      <el-table-column label="操作" width="240px">
        <template slot-scope="s">
          <el-button
            v-if="s.row.isinit === 1"
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click="_OpenUpdateStorageModel(s.row.kstorage_id)"
            >修改</el-button
          >
          <el-button
            v-if="s.row.isinit === 0"
            size="mini"
            icon="el-icon-refresh-right"
            @click="_OpenInitStorageModel(s.row.kstorage_id)"
            >初始化</el-button
          >
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click="_DelStorage(s.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- ------------- 分页 ------------- -->
    <div class="page-box">
      <el-pagination
        background
        layout="total, prev, pager, next, sizes, jumper"
        :current-page.sync="p.pageNo"
        :page-size.sync="p.pageSize"
        :total="p.dataCount"
        :page-sizes="[25, 50, 100, 150]"
        @current-change="_Load()"
        @size-change="_Load()"
      >
      </el-pagination>
    </div>

    <!-- 增改组件 -->
    <nu-storage ref="nu-storage"></nu-storage>

    <!-- 打印预览 -->
    <print-storage ref="print-storage"></print-storage>
  </div>
</template>

<script>
import NuStorage from "./NUStorage";
import { getStorageTable, getWarehouseList, delStorage } from "../api"; //页面专有接口
import OrganizationPicker from "@/components/organizationPicker";
import PrintStorage from "./PrintStorage";
export default {
  name: "StorageManage",
  components: {
    NuStorage,
    OrganizationPicker,
    PrintStorage,
  },
  data() {
    return {
      p: {
        kor_id: "", //组织ID
        kwh_id: "", //仓库ID
        pageNo: 1, //当前页
        pageSize: 25, //分页行数
        dataCount: 0, //数据总行数
      },
      TableData: [], // 数据集合
      kwhList: [], //仓库列表
    };
  },
  watch: {
    //监听组织ID变动加载仓库列表
    "p.kor_id": {
      handler() {
        this._GetWarehouseList();
      },
    },
    "p.kwh_id": {
      handler() {
        this._Load();
      },
    },
  },
  methods: {
    //加载表格数据
    _Load() {
      getStorageTable({
        pageNo: this.p.pageNo,
        pageSize: this.p.pageSize,
        kor_id: this.p.kor_id,
        kwh_id: this.p.kwh_id,
      })
        .then((res) => {
          this.TableData = res.data.data;
          this.p.dataCount = res.data.dataCount;
        })
        .catch((err) => {
          this.TableData = [];
          if (err.code === 140001) {
            return this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //加载仓库列表数据
    _GetWarehouseList() {
      getWarehouseList({
        kor_id: this.p.kor_id,
        pageNo: 1,
        pageSize: 999,
      })
        .then((res) => {
          this.kwhList = res.data; //重写数据
          this.$nextTick(() => {
            this.p.kwh_id = res.data[0]["kwh_id"];
            this.$nextTick(() => {
              if (res.data.length === 1) {
                this._Load();
              }
            });
          });
        })
        .catch((err) => {
          this.kwhList = []; //清空仓库列表
          this.p.kwh_id = ""; //清空仓库选择
          this.storageTableData = [];
          if (err.code === 130003) {
            return this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //打开库位创建组件
    _OpenNewStorageModel() {
      this.$refs["nu-storage"].open(0, this.p.kwh_id);
    },

    //打开仓库修改组件
    _OpenUpdateStorageModel(id) {
      this.$refs["nu-storage"].open(1, null, id);
    },

    //打开批量库位添加组件
    _OpenBatchNewStorageModel() {
      this.$refs["nu-storage"].open(3, this.p.kwh_id);
    },

    //打开初始化库位组件
    _OpenInitStorageModel(kst_id) {
      this.$refs["nu-storage"].open(4, null, kst_id);
    },

    //打开打印预览
    _OpenPrintStorage() {
      this.$refs["print-storage"].open(1);
    },

    //删除
    _DelStorage(data) {
      var _vm = this;
      this.$utils.confirm(
        "确认删除库位【" +
          data.tag_id +
          '】吗？</br><b style="color:red">此操作将同步删除库位下的所有货物记录并且不可撤销!</b>',
        () => {
          delStorage({ kst_id: data.kstorage_id })
            .then((res) => {
              _vm.$utils.success(res.err_msg);
              _vm._Load(); //重载表格数据
            })
            .catch((err) => {
              _vm.$utils.error(err.err_msg);
            });
        }
      );
    },
  },
  mounted() {},
};
</script>

<style scoped></style>
