/*
 * @Author: 智客云网络科技
 * @Date: 2021-11-01 15:00:52
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-22 00:04:44
 * @Description: 页面专属接口管理
 * @FilePath: \src\views\App\Storage\api.js
 */

import http from "@/api";

/**************************** 仓库操作 ****************************/

// 添加新仓库
export const createWarehouse = (params) =>
  http.post("/v1/Warehouse/CreateWarehouse", params);

// 删除仓库
export const delWarehouse = (params) =>
  http.delete("/v1/Warehouse/DelWarehouse", params);

// 修改仓库
export const updateWarehouse = (params) =>
  http.put("/v1/Warehouse/UpdateWarehouse", params);

// 根据ID查询仓库信息
export const getWarehouseInfo = (params) =>
  http.get("/v1/Warehouse/GetWarehouseInfo", params);

// 获取仓库表格
export const getWarehouseTable = (params) =>
  http.get("/v1/Warehouse/GetWarehouseTable", params);

// 获取仓库列表
export const getWarehouseList = (params) =>
  http.get("/v1/Warehouse/GetWarehouseList", params);

/**************************** 库位操作 ****************************/

// 添加新库位
export const createNewStorage = (params) =>
  http.post("/v1/Storage/CreateNewStorage", params);

// 批量添加空白库位
export const createBatchStorage = (params) =>
  http.post("/v1/Storage/CreateBatchStorage", params);

// 初始化库位
export const initStorage = (params) =>
  http.put("/v1/Storage/InitStorage", params);

// 删除库位
export const delStorage = (params) =>
  http.delete("/v1/Storage/DelStorage", params);

// 修改库位
export const updateStorage = (params) =>
  http.upt("/v1/Storage/UpdateStorage", params);

// 根据ID查询库位信息
export const getStorageInfo = (params) =>
  http.get("/v1/Storage/GetStorageInfo", params);

// 获取库位表格
export const getStorageTable = (params) =>
  http.get("/v1/Storage/GetStorageTable", params);

// 获取库位列表
export const getStorageList = (params) =>
  http.get("/v1/Storage/GetStorageList", params);
