<!--
 * @Author: 智客云网络科技
 * @Date: 2021-11-17 23:15:19
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-21 23:58:26
 * @Description: 组织选择器
 * @FilePath: \src\components\organizationPicker\index.vue
-->

<template>
  <el-select
    size="small"
    :value="value"
    @input="_SetValue"
    :disabled="kor_lock"
    v-model="kor_id"
  >
    <el-option
      v-for="item in korList"
      :key="item.korganization_id"
      :label="item.kor_name"
      :value="item.korganization_id"
    ></el-option>
  </el-select>
</template>

<script>
import { getKorIdList } from "./api"; //接口
export default {
  name: "organizationPicker",
  props: {
    value: {
      type: String,
      default: "",
    }, //控制组件是否显示
  },
  data() {
    return {
      kor_lock: false, //锁定组织选择
      kor_id: "", //组织ID
      korList: [], //组织列表
    };
  },
  watch: {
    kor_id() {
      this.$nextTick(() => {
        this._SetValue(this.kor_id);
      });
    },
  },
  methods: {
    //加载组织列表数据
    _GetKorIdList() {
      getKorIdList()
        .then((res) => {
          this.korList = res.data; //重写数据
          this.$nextTick(() => {
            this.kor_id = res.data[0]["korganization_id"];
          });
          if (res.data.length === 1) {
            this.kor_lock = true;
          }
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //设置组织ID
    _SetValue(val) {
      this.$emit("input", val);
    },
  },
  mounted() {
    this._GetKorIdList(); //加载组织列表
  },
};
</script>
