/*
 * @Author: 智客云网络科技
 * @Date: 2021-10-16 16:46:06
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-01-21 23:58:22
 * @Description: 页面专属接口管理
 * @FilePath: \src\components\organizationPicker\api.js
 */

import http from "@/api";

// 获取组织ID列表
export const getKorIdList = (params) =>
  http.get("/v1/Auth/GetOrganizationIdList", params, false);
