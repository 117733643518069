<!--
 * @Author: 智客云网络科技
 * @Date: 2021-05-20 11:23:11
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-16 16:11:20
 * @Description: 创建 修改 库位
 * @FilePath: \src\views\App\Storage\Storage\NUStorage.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible.sync="isShow"
    width="950px"
    top="10vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <el-form :model="m" :inline="true" ref="m" label-width="100px">
          <el-form-item
            label="存储方式："
            v-if="id === 0 || (id !== 3 && id !== 1)"
            style="width: 90%"
          >
            <el-radio v-model="m.type" :label="140010">货架存放</el-radio>
            <el-radio v-model="m.type" :label="140011">货架行存</el-radio>
            <el-radio v-model="m.type" :label="140012">散堆存放</el-radio>
            <el-radio v-model="m.type" :label="140013">垛堆存放</el-radio>
            <el-radio v-model="m.type" :label="140014">成组堆码存放</el-radio>
          </el-form-item>

          <el-form-item
            label="货架编码："
            v-if="id !== 3 && (m.type === 140010 || m.type === 140011)"
            style="width: 45%"
          >
            <el-input
              size="small"
              v-model="m.role_shelf"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="货架行号："
            v-if="id !== 3 && (m.type === 140010 || m.type === 140011)"
            style="width: 45%"
          >
            <el-input
              size="small"
              v-model="m.role_line"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item
            label="库位编码："
            v-if="id !== 3 && m.type !== 140011"
            style="width: 45%"
          >
            <el-input
              size="small"
              v-model="m.role_position"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="客户绑定：" v-if="id !== 3" style="width: 45%">
            <el-input
              size="small"
              :readonly="true"
              v-model="kcu_name"
              @click.native="_OpenCustomerSelectModel"
              suffix-icon="xxxx"
            ></el-input>
          </el-form-item>

          <el-form-item label="仓库状态：" v-if="id !== 3" style="width: 30%">
            <el-switch
              size="small"
              v-model="m.status"
              :active-value="1"
              :inactive-value="0"
            ></el-switch>
          </el-form-item>

          <div class="from-notes-box" v-if="id !== 3">
            <el-form-item label="描述：" style="width: 90%; display: block">
              <el-input
                size="small"
                v-model="m.notes"
                type="textarea"
                :rows="5"
              ></el-input>
            </el-form-item>
          </div>

          <el-form-item label="生成数量：" v-if="id === 3" style="width: 45%">
            <el-input-number
              v-model="m.quantity"
              size="small"
              :min="1"
              :max="1000"
            ></el-input-number>
          </el-form-item>
        </el-form>

        <el-divider></el-divider>

        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="_CreateNewStorage()"
          v-if="id == 0"
          >提交</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="_BatchCreateStorage()"
          v-else-if="id == 3"
          >提交</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="_InitStorage()"
          v-else-if="id == 4"
          >提交</el-button
        >
        <el-button
          size="small"
          type="primary"
          icon="el-icon-check"
          @click="_UpdateStorage()"
          v-else
          >保存</el-button
        >
        <el-button size="small" icon="el-icon-close" @click="isShow = false"
          >取消</el-button
        >
      </el-card>
    </div>

    <!-- 客户选择器 -->
    <customer-select
      :show.sync="show_customer_select"
      @confirm-select="_SelectCustomer"
    ></customer-select>
  </el-dialog>
</template>

<script>
import {
  createNewStorage,
  initStorage,
  createBatchStorage,
  updateStorage,
  getStorageInfo,
} from "../api"; //页面专有接口
import CustomerSelect from "@/components/customerSelect";
export default {
  components: {
    CustomerSelect,
  },
  data() {
    return {
      m: {
        type: 140010, //存储方法
        role_shelf: "", //货架号
        role_line: "", //货架行号
        role_position: "", //储位号
        status: 1, //库位状态
        notes: "", //描述
        quantity: 1, //批量生成数量
      },
      id: 0, //
      title: "", //弹窗标题
      isShow: false, //显示控制
      loading: true, //加载控制
      kwh_id: null, //仓库ID
      kst_id: null, //库位ID

      //客户信息
      show_customer_select: false, //控制客户选择器显示
      kcu_id: "", //客户ID
      kcu_name: "", //客户姓名
    };
  },
  methods: {
    // 打开
    open(id, kwh_id = null, kst_id = null) {
      this.isShow = true; //显示组件
      this.id = id; //重写ID
      this.kwh_id = kwh_id; //仓库ID
      this.kst_id = kst_id; //库位ID
      this.$nextTick(() => {
        if (id === 0) {
          //新建
          this.title = "添加新库位";
          this.loading = false;
        } else if (id === 1) {
          //修改
          this._GetStorageInfo(kst_id);
          this.title = "修改库位信息";
        } else if (id === 3) {
          //批量新建
          this.title = "批量创建空白库位";
          this.loading = false;
        } else if (id === 4) {
          //批量新建
          this.title = "初始化库位";
          this.loading = false;
        } else {
          //未知情况 直接关闭
          this.loading = false;
        }
      });
    },

    //添加新库位
    _CreateNewStorage() {
      var m = this.m;
      createNewStorage({
        kwh_id: this.kwh_id, //仓库ID
        type: m.type, //存储方式
        role_shelf: m.role_shelf, //货架号
        role_line: m.role_line, //货架行号
        role_position: m.role_position, //库位号
        kcu_id: this.kcu_id, //客户ID
        status: m.status, //状态
        note: m.notes, //描述
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //批量添加空白库位
    _BatchCreateStorage() {
      var m = this.m;
      createBatchStorage({
        kwh_id: this.kwh_id, //仓库ID
        quantity: m.quantity, //生成数量
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //初始化库位
    _InitStorage() {
      var m = this.m;
      initStorage({
        kst_id: this.kst_id, //库位ID
        type: m.type, //存储方式
        role_shelf: m.role_shelf, //货架号
        role_line: m.role_line, //货架行号
        role_position: m.role_position, //库位号
        kcu_id: this.kcu_id, //客户ID
        status: m.status, //状态
        notes: m.notes, //描述
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //修改库位信息
    _UpdateStorage() {
      var m = this.m;
      updateStorage({
        kst_id: this.kst_id, //库位ID
        role_shelf: m.role_shelf, //货架号
        role_line: m.role_line, //货架行号
        role_position: m.role_position, //库位号
        kcu_id: this.kcu_id, //客户ID
        status: m.status, //状态
        notes: m.notes, //描述
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this.isShow = false;
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //获取库位信息
    _GetStorageInfo(kst_id) {
      getStorageInfo({ kst_id })
        .then((res) => {
          let m = this.m;
          let d = res.data;
          m.type = d.type; //存储方式
          m.role_shelf = d.role_shelf; //货架号
          m.role_line = d.role_line; //货架行号
          m.role_position = d.role_position; //库位号
          m.status = d.status; //状态
          m.notes = d.notes; //描述
          this.loading = false;

          //生成客户信息预览
          this._SelectCustomer(JSON.parse(d.kcu_info));
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //打开客户选择模块
    _OpenCustomerSelectModel() {
      this.show_customer_select = true;
    },

    //确认客户选择
    _SelectCustomer(customer_info) {
      let ci = customer_info;
      console.log(customer_info);
      this.kcu_id = ci.kcu_id;
      this.kcu_name = ci.kcu_name;
    },

    //关闭
    _HandleClose() {
      Object.assign(this.$data, this.$options.data());
      this.$parent._Load(); //通知父组件刷新数据
    },
  },
  created() {},
};
</script>

<style>
.address-box .el-form-item__content {
  width: calc(100% - 100px);
}
</style>
