<!--
 * @Author: 智客云网络科技
 * @Date: 2021-12-14 17:02:24
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-03-03 11:37:53
 * @Description: 客户选择器
 * @FilePath: \src\components\customerSelect\index.vue
-->
<template>
  <el-dialog
    v-drag2anywhere
    :title="title"
    :visible="show"
    width="60%"
    top="5vh"
    append-to-body
    destroy-on-close
    :close-on-click-modal="false"
    @close="_HandleClose()"
    custom-class="full-dialog"
  >
    <div class="vue-box">
      <el-card
        shadow="never"
        v-loading="loading"
        element-loading-text="拼命加载中..."
      >
        <template>
          <!-- ------------- 检索 ------------- -->
          <div class="table-search-box">
            <!-- <div class="table-search-title">检索</div> -->
            <div class="table-search-content">
              <div class="table-search-item">
                <label>组织筛选：</label>
                <organization-picker v-model="kor_id"></organization-picker>
              </div>

              <div class="table-search-item">
                <label>名称筛选：</label>
                <el-input
                  size="small"
                  v-model="search_key"
                  @keyup.enter.native="_Load()"
                  suffix-icon="xxxx"
                ></el-input>
              </div>

              <div class="table-search-btn">
                <el-button
                  size="small"
                  type="primary"
                  icon="el-icon-search"
                  @click="
                    pageNo = 1;
                    _Load();
                  "
                  >查询</el-button
                >
              </div>
            </div>
          </div>

          <el-table
            :data="TableData"
            border
            size="small"
            @row-dblclick="_SelectRow"
            style="width: 100%"
          >
            <!-- ID -->
            <el-table-column
              label="ID"
              prop="Aid"
              align="center"
              width="50px"
            ></el-table-column>

            <!-- 用户名 -->
            <el-table-column
              label="用户名"
              prop="username"
              align="center"
              width="180px"
            >
            </el-table-column>

            <!-- 客户昵称 -->
            <el-table-column
              label="客户昵称"
              prop="nickname"
              align="center"
              width="200px"
            >
            </el-table-column>

            <!-- 手机号码 -->
            <el-table-column
              label="手机号码"
              prop="phone"
              align="center"
              width="160px"
            ></el-table-column>

            <!-- 邮箱 -->
            <el-table-column
              label="邮箱"
              prop="email"
              align="center"
            ></el-table-column>

            <!-- 注册时间 -->
            <el-table-column
              label="注册时间"
              prop="gen_time"
              align="center"
              width="200px"
            ></el-table-column>
          </el-table>

          <!-- ------------- 分页 ------------- -->
          <div class="page-box">
            <el-pagination
              background
              layout="total, prev, pager, next, sizes, jumper"
              :current-page.sync="pageNo"
              :page-size.sync="pageSize"
              :total="dataCount"
              :page-sizes="[15, 50, 100, 150, 200]"
              @current-change="_Load()"
              @size-change="_Load()"
            >
            </el-pagination>
          </div>
        </template>
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
import { getCustomerTable } from "./api"; //页面专有接口
import OrganizationPicker from "@/components/organizationPicker";
export default {
  name: "declareItemsSelect",
  components: {
    OrganizationPicker,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    }, //控制组件是否显示
  },
  data() {
    return {
      title: "客户选择器", //弹窗标题
      loading: true, //加载控制
      pageNo: 1, //当前页
      pageSize: 15, //分页行数
      dataCount: 0, //数据总行数
      search_key: "", //搜索词
      kor_id: "", //组织ID
      TableData: [], //表格数据
    };
  },
  watch: {
    //监听组织ID变动刷新表格
    kor_id: {
      handler(val) {
        if (val !== "") {
          this._Load();
        }
      },
    },
  },
  methods: {
    //加载词条数据
    _Load() {
      getCustomerTable({
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        kor_id: this.kor_id,
        searchKey: this.search_key,
      })
        .then((res) => {
          this.TableData = res.data.data;
          this.dataCount = res.data.dataCount;
          this.loading = false;
        })
        .catch((err) => {
          this.TableData = [];
          if (err.code === 210001) {
            return this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //选择词条
    _SelectRow(row) {
      this.$emit("confirm-select", {
        kcu_id: row.kuser_id,
        kcu_name: row.nickname,
      });
      this.$nextTick(() => {
        this._HandleClose();
      });
    },

    //关闭
    _HandleClose() {
      this.$emit("update:show", false); //触发input事件，并传入新值
      Object.assign(this.$data, this.$options.data());
    },
  },
};
</script>
