/*
 * @Author: 智客云网络科技
 * @Date: 2021-11-01 15:00:52
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-12 19:03:11
 * @Description: 页面专属接口管理
 * @FilePath: \src\components\customerSelect\api.js
 */

import http from "@/api";

/**************************** 词条分类操作 ****************************/

//获取客户表格
export const getCustomerTable = (params) =>
  http.get("/v1/Customer/GetCustomerTable", params, false);
